/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useState } from "react";
import SignupDesktop from "./SignupDesktop";
import SignupScreenDesktop from "./SignupScreenDesktop";
import GeneratedLinkDesktop from "./GeneratedLinkDesktop";
import Error from "../Error";
import { Provider } from "react-redux";
import { store } from "../../../newCheckout/redux/store";

export default function UserFlowDesktop({ initialValues }) {
  const [step, setStep] = useState(1);
  const [accentColor, setAccentColor] = useState(initialValues?.accentColor);
  const [user, setUser] = useState({ user: null, shouldShowToast: false });
  const brandSlug = "" || window.location.pathname.split("/")[1];
  return (
    <Provider store={store}>
      {brandSlug && !initialValues?.disabled ? (
        <div
          className="fa-user-flow desktop"
          css={css`
            min-height: 100vh;
          `}
        >
          {step === 1 && (
            <SignupDesktop initialValues={initialValues} setStep={setStep} />
          )}
          {step === 2 && (
            <SignupScreenDesktop
              initialValues={initialValues}
              setStep={setStep}
              setUser={setUser}
              step={step}
              accentColor={accentColor}
            />
          )}
          {step === 3 && (
            <GeneratedLinkDesktop
              setStep={setStep}
              user={user}
              brandSlug={brandSlug}
              initialValues={initialValues}
            />
          )}
        </div>
      ) : (
        <Error
          brandName={initialValues?.brandName || ""}
          disabled={initialValues?.disabled || false}
        />
      )}
    </Provider>
  );
}
