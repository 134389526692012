/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import InklndLogo from "../../../../../assets/images/icons/inkind-logo.png";

export default function MainHeader({ children }) {
  return <div className="main-header">{children}</div>;
}

export function AddPhoneNumberHeader({ setCurPage }) {
  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          position: relative;
          justify-content: center;
        `}
      >
        <BackButton setCurPage={setCurPage} />
        <img
          css={css`
            width: 52px;
          `}
          src={InklndLogo}
          alt="inkind-logo"
        />
      </div>
      <h1
        className="capture-db__header-name"
        css={css`
          font-weight: 600;
          font-size: 20px;
          color: #2e2e2d;
          text-align: center;
          flex: 1;
          margin-top: 50px;
          margin-bottom: 25px;
          padding-right: 18px;
        `}
      >
        Create your Ambassador Account
      </h1>
      <p
        css={css`
          margin-bottom: 30px;
          font-size: 14px;
        `}
      >
        Sign up with your phone
      </p>
    </>
  );
}
export function OTPCodeHeader({ setCurPage, number }) {
  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          position: relative;
          justify-content: center;
        `}
      >
        <BackButton setCurPage={setCurPage} />
        <img
          css={css`
            width: 52px;
          `}
          src={InklndLogo}
          alt="inkind-logo"
        />
      </div>
      <h1
        className="capture-db__header-name"
        css={css`
          font-weight: 600;
          font-size: 20px;
          color: #2e2e2d;
          text-align: center;
          flex: 1;
          margin-top: 50px;
          margin-bottom: 25px;
          padding-right: 18px;
        `}
      >
        Almost there!
      </h1>
      <p
        css={css`
          margin-bottom: 30px;
          font-size: 14px;
        `}
      >
        Great deals and opportunities await you
      </p>
      <p
        css={css`
          margin-bottom: 30px;
          font-size: 14px;
        `}
      >
        We texted a code to <b>{`${number}`}</b>
      </p>
    </>
  );
}

export function GenericHeader({ setCurPage }) {
  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          position: relative;
          justify-content: center;
        `}
      >
        <BackButton setCurPage={setCurPage} />
        <img
          css={css`
            width: 52px;
          `}
          src={InklndLogo}
          alt="inkind-logo"
        />
      </div>
      <h1
        className="capture-db__header-name"
        css={css`
          font-weight: 600;
          font-size: 20px;
          color: #2e2e2d;
          text-align: center;
          flex: 1;
          margin-top: 50px;
          margin-bottom: 25px;
          padding-right: 18px;
        `}
      >
        Almost there!
      </h1>
      <p
        css={css`
          margin-bottom: 30px;
          font-size: 14px;
        `}
      >
        Great deals and opportunities await you
      </p>
    </>
  );
}

export function BackButton({ setCurPage = null }) {
  return (
    <button
      css={css`
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        position: absolute;
        left: 0;
      `}
      className="back-button"
      onClick={() =>
        setCurPage((curPage) =>
          curPage === 5 ? 3 : curPage === 6 ? 2 : curPage - 1,
        )
      }
    >
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
  );
}
