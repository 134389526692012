/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export default function Faqs({ desktop }) {
  const [data, setData] = useState(faqsData);
  const [lastClicked, setLastClicked] = useState(null);
  const openCloseFAQs = (index) => {
    const newData = data.map((faq, i) => {
      if (i === index) {
        return { ...faq, open: !faq.open };
      }
      return faq;
    });
    setData(newData);
    setLastClicked(index);
  };
  return (
    <div className="faqs">
      <h2>FAQs</h2>
      <section className="faqs-data">
        {data && data.length
          ? data.map((faq, index) => {
              return (
                <div key={index + 1} className="faq">
                  <h3
                    className={`question${desktop ? "-desktop" : ""}`}
                    onClick={() => openCloseFAQs(index)}
                  >
                    <span className="question-data">{faq.question}</span>
                    <span className="icon">
                      <FontAwesomeIcon
                        icon={faq.open ? faChevronUp : faChevronDown}
                      />
                    </span>
                  </h3>
                  {
                    <div className={`answer ${faq.open ? "show-answer" : ""}`}>
                      {faq.answer}
                    </div>
                  }
                </div>
              );
            })
          : null}
      </section>
    </div>
  );
}

const faqsData = [
  {
    question: "How do guests use my referral link?",
    answer:
      "Potential guests can claim your offer and will be prompted to download inKind to pay their bill in-store using the inKind app. Payment through inKind is faster than paying by card.",
    open: false,
  },
  {
    question: "What is inKind?",
    answer:
      "inKind partners with the world’s best restaurants and operators to bring in amazing guests and reward them for their support. The inKind App is the best way for guests to unlock rewards and pay their bill at thousands of restaurants.",
    open: false,
  },
  {
    question: "Where should I share my referral link?",
    answer:
      "Share your referral link wherever potential guests might benefit from seeing it! TikTok, Facebook and instagram bio’s are a great place to start!",
    open: false,
  },
  {
    question: "Where can I spend the dining credit I earn?",
    answer:
      "Dining credit you earn through successfully getting a guest to visit a restaurant can be spent at any of the 1,750+ restaurants on the inKind app (Download it here). View a full list of participating restaurants here.",
    open: false,
  },
  {
    question: "Have more questions?",
    answer: "Feel free to contact us at support@inkind.com",
    open: false,
  },
];
