import React from "react";
import PassTier from "./PassTier";
import subscribeBadge from "../../../assets/images/icons/credit-back-icon.svg";

const BrandDetails = ({
  brandToUse,
  curLoc,
  tiers,
  cloudinaryPath,
  userLocErr,
  ctaDisplay,
  tags,
  getFeaturedTags,
  purchaseModeTiers,
  totalLocationCount,
}) => {
  const [tagsText, setTagsText] = React.useState("");

  React.useEffect(() => {
    setTagsText(getFeaturedTags(brandToUse)?.join(" • ") ?? "");
  }, [tags, brandToUse]);

  const hero =
    brandToUse?.branding.hero_image.value.indexOf(".png") !== -1
      ? brandToUse?.branding.hero_image.value.replace(".png", ".jpg")
      : brandToUse?.branding.hero_image.value;
  return (
    <div className="details-container">
      <div
        className="loc-tile"
        style={{
          backgroundImage: `url(${cloudinaryPath}/b_black,o_67,c_fit,w_800/${hero})`,
        }}
      >
        <div
          className="loc-logo"
          style={{
            backgroundImage: `url(${cloudinaryPath}/w_250/${brandToUse?.branding.logo.dark_mode})`,
          }}
        />
      </div>
      <div className="text-container">
        <div className="loc-header">{brandToUse?.name}</div>
        <div className="loc-tags">{tagsText}</div>
        <div className="loc-distance">
          {curLoc?.location?.address}, {curLoc?.location?.city},{" "}
          {curLoc?.location?.state ?? ""} {curLoc?.location?.zip_code ?? ""}
        </div>
        <div
          className="loc-distance"
          style={{ display: !!userLocErr ? "none" : "block" }}
        >
          {curLoc?.staticDistance + " Miles Away"}
        </div>
      </div>
      <div className="summary-container">{brandToUse?.branding.summary}</div>
      {brandToUse?.branding?.gallery?.images?.length > 0 && (
        <div className="discovery-location-gallery-container">
          <div className="discovery-location-gallery">
            {brandToUse?.branding?.gallery?.images?.map((image, i) => {
              return (
                <div
                  className="discovery-image"
                  key={i}
                  style={{
                    backgroundImage: `url(${cloudinaryPath}/b_black,c_fit,w_250/${image})`,
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      )}

      {ctaDisplay !== "none" && !!purchaseModeTiers ? (
        <div className="bonus-outer">
          <div className="bonus-container">
            <div className="loc-header">Get a dining bonus here</div>
            Add a balance to get a bonus, the more you spend the larger bonus
            you’ll receive! Claim your balance here and hundreds of other inKind
            partner locations.
          </div>
          <div className="tiers-container">
            {tiers?.map((tier) => {
              let useBonus =
                String(tier.bonus_amount).slice(-1) === "6" ||
                String(tier.bonus_amount).slice(-1) === "1"
                  ? tier.bonus_amount - 1
                  : tier.bonus_amount;
              return (
                <PassTier
                  key={tier.ico_id}
                  payAmount={tier.pay_amount}
                  getAmount={tier.get_amount}
                  bonusAmount={useBonus}
                  icoId={tier.ico_id}
                />
              );
            })}
          </div>
          <div className="decorated">
            <span>OR</span>
          </div>
          <a href={curLoc?.purchase_page_link} target="blank">
            <div className="map-pp-button">
              Shop individual house accounts
              <div className="pp-subtext">
                Only redeemable at {brandToUse?.location_ids?.length}{" "}
                {brandToUse?.location_ids?.length === 1
                  ? "location"
                  : "locations"}
              </div>
            </div>
          </a>
        </div>
      ) : ctaDisplay !== "none" && !purchaseModeTiers ? (
        <div className="bonus-outer">
          <div className="map-subscribe-container">
            <img src={subscribeBadge} className="map-subscribe-badge" />
            <div className="map-subscribe-and-earn">SUBSCRIBE AND EARN</div>
            <div className="loc-header">Earn 20% back when you dine here</div>
            <div>
              inKind Pass Subscribers earn 20% back when they dine here and at
              {totalLocationCount}+ other restaurants, bars and cafés.
            </div>
            <a
              href={
                ctaDisplay === "checkout"
                  ? `https://pass.inkind${
                      window.location.hostname === "app.inkind-staging.com"
                        ? "-staging"
                        : ""
                    }.com/checkout/subscribe`
                  : `https://pass.inkind${
                      window.location.hostname === "app.inkind-staging.com"
                        ? "-staging"
                        : ""
                    }.com`
              }
              target="blank"
            >
              <div className="pass-button">Subscribe & get 1 month free</div>
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BrandDetails;
