/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import InklndLogo from "../../../../../assets/images/icons/inkind-logo.png";

export default function FooterDesktop() {
  const learnMore = () => {
    window.location.href = "https://inkind.com";
  };
  const contactUs = () => {
    window.location.href = "mailto:support@inkind.com";
  };
  return (
    <div
      className="footer-desktop"
      css={css`
        background: #fff;
        display: flex;
        align-items: center;
      `}
    >
      <div className="brand-logo-image">
        <img src={InklndLogo} alt="brand-logo" />
      </div>
      <div className="info-section">
        <h2>About inKind</h2>
        <p>
          InKind specializes in providing alternative financing solutions for
          restaurants. Offering creative ways for restaurants to generate
          immediate revenue while providing customers with a way to support
          their favorite dining establishments.
        </p>
        <div
          className="buttons"
          css={css`
            display: flex;
          `}
        >
          <button type="button" onClick={learnMore} className="learn-more">
            Learn More
          </button>
          <button type="button" onClick={contactUs} className="contact-us">
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
}
