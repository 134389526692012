import React from "react";
import InklndLogo from "../../../../assets/images/icons/inkind-logo.png";
import InklndWhiteLogo from "../../../../assets/images/icons/inkind-logo-white.png";
import { calculateFontColorFor } from "@/ambassador/2fa-user-flow/index";

export default function Header({ initialValues, replaceLogo }) {
  const textColor = calculateFontColorFor(initialValues?.backgroundColor);
  const inkindLogo =
    textColor.toUpperCase() === "#FFF" || textColor.toUpperCase() === "#FFFFFF"
      ? InklndWhiteLogo
      : InklndLogo;
  const imgUrl =
    textColor.toUpperCase() === "#FFF" || textColor.toUpperCase() === "#FFFFFF"
      ? initialValues?.brandDarkLogo
      : initialValues?.brandLogo;
  return (
    <section className="header">
      <img
        src={replaceLogo ? inkindLogo : InklndLogo}
        className="inkind-logo"
        alt="InKind Logo"
      />
      <img
        src={replaceLogo ? imgUrl : initialValues?.brandLogo}
        className="brand-logo"
        alt="Brand Logo"
      />
    </section>
  );
}
