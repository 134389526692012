/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import ErrorImg from "../../../../assets/images/ambassador/error.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import InklndLogo from "../../../../assets/images/icons/inkind-logo.png";
import { isMobileDevice } from ".";

export default function Error({ disabled = false, brandName = "" }) {
  // TODO: uncomment when web portal is ready
  // const exploreActiveOffers = () => {
  //   console.log("Explore active offers clicked");
  //   // window.location.href = '';
  // };
  const openInkind = () => {
    window.location.href = "https://www.inkind.com";
  };
  const sendEmail = () => {
    window.location.href = "mailto:support@inkind.com";
  };
  return (
    <div
      className="error-fa-flow"
      css={css`
        padding: 20px;
      `}
    >
      {!isMobileDevice() && (
        <div className="inkind-logo">
          <img src={InklndLogo} className="inkind-logo-img" alt="InKind Logo" />
        </div>
      )}
      <div
        className="error-img"
        css={css`
          text-align: center;
        `}
      >
        <img src={ErrorImg} alt="Error" />
      </div>
      <div
        className="text-section"
        css={css`
          text-align: center;
        `}
      >
        <h2
          css={css`
            font-size: 31px;
            font-style: italic;
            font-weight: 400;
            font-family: Times;
          `}
        >
          Oops!
        </h2>
        <p
          css={css`
            font-weight: 400;
            font-size: 14px;
            margin: 10px 0 40px 0;
          `}
        >
          {brandName ? (
            `${brandName}’s ambassador program is currently closed. Please check back soon!`
          ) : (
            <span className="text-and-button">
              Uh oh! We couldn't find the page you're looking for. We suggest
              going to 
              <button type="button" onClick={openInkind}>
                inKind.com&nbsp;
              </button>{" "}
              or
              <button type="button" onClick={sendEmail}>
                &nbsp;emailing us&nbsp;
              </button>
               if you think this is a mistake.
            </span>
          )}
        </p>

        {/*TODO: uncomment when web portal is ready*/}
        {/*<button*/}
        {/*  onClick={exploreActiveOffers}*/}
        {/*  type="button"*/}
        {/*  id="sign-up"*/}
        {/*  css={css`*/}
        {/*    background: #000;*/}
        {/*    color: #fff;*/}
        {/*    font-size: 15px;*/}
        {/*    border-radius: 30px;*/}
        {/*    padding: 10px 30px;*/}
        {/*  `}*/}
        {/*>*/}
        {/*  Explore active offers*/}
        {/*</button>*/}
      </div>
    </div>
  );
}
