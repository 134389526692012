/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React from "react";
import { useFormikContext } from "formik";
import { calculateFontColorFor } from "../../lib/calculateFontColor";

const SubmitButton = ({ verb, isBslCheckout, accentColor, curPage }) => {
  const { values, isSubmitting } = useFormikContext();
  return (
    <button
      disabled={isSubmitting}
      className={`login-ca__button login-ca__continue-button ${
        isSubmitting ? "loading-btn" : "action"
      } ${isBslCheckout ? "login-ca__bsl" : ""}`}
      type="submit"
      css={css`
        background-color: ${(values?.phone.length > 0 && curPage === 0) ||
        (values?.sixDigitCode.length > 0 && curPage === 2)
          ? accentColor
          : "rgba(116, 120, 108, 0.15)"};
      `}
    >
      {verb}
    </button>
  );
};

export default SubmitButton;
