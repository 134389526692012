import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { handlePost } from "./utilities";

const ForgotPassword = ({ email, isPartnerPortal }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [emailError, setEmailError] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (/^[^@]+@[^@]+\.[^@]+$/.test(email) === false) {
      setEmailError("Invalid email address.");
      return false;
    }
    const forgotPwBody = JSON.stringify({
      user: {
        email,
      },
    });
    const success = await handlePost(
      "/api/v1/users/forgot_password.json",
      forgotPwBody,
      setEmailError,
    );
    setSuccessMessage(success?.message);
  };

  React.useEffect(() => {
    if (!isOpen) {
      setSuccessMessage(null);
    }
  }, [isOpen]);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="login-ca__forgot-pw"
      >
        Forgot password?
      </button>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className={`dialog ${
            isPartnerPortal ? "partner-portal__dialog" : ""
          }`}
          onClose={() => setIsOpen(false)}
        >
          <div className=" inner">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="dialog-overlay " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="trick-span" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child as={React.Fragment}>
              <div className="reset-dialog-block reset">
                {successMessage ? (
                  <>
                    <div className="email-row">
                      <button
                        className="reset-dialog-button"
                        onClick={() => setIsOpen(false)}
                      >
                        <XMarkIcon className="xicon" />
                      </button>
                      <div className="head">Reset instructions sent</div>
                      <div className="reset-info">{successMessage}</div>
                      <button
                        type="button"
                        onClick={() => setIsOpen(false)}
                        className="submit-button"
                      >
                        Log in
                      </button>
                    </div>
                    {emailError && <span className="error">{emailError}</span>}
                  </>
                ) : (
                  <div className="email-row">
                    <button
                      className="reset-dialog-button"
                      onClick={() => setIsOpen(false)}
                    >
                      <XMarkIcon className="xicon" />
                    </button>
                    <div className="head">Reset your inKind password</div>
                    <div
                      placeholder="Email"
                      type="email"
                      className="email-input login-ca__reset-field login-ca__disabled-field"
                    >
                      {email}
                    </div>
                    {emailError && (
                      <span className="error reset-error">{emailError}</span>
                    )}
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="submit-button"
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ForgotPassword;
