/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import CopyIcon from "../../../../../assets/images/ambassador/CopyIcon.svg";
import ShareIcon from "../../../../../assets/images/ambassador/share-icon.svg";
import LearnMore from "../LearnMore";
import Faqs from "../Faqs";
import Header from "../Header";
import FooterDesktop from "./FooterDesktop";
import Error from "../Error";
import GetAccessSection from "./GetAccessSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import CarouselSectionDesktop from "./CarouselSectionDesktop";
import Tooltip from "../Tooltip";

export default function GeneratedLinkDesktop({
  user,
  setStep,
  brandSlug,
  initialValues,
}) {
  const [offerData, setOfferData] = useState(null);
  const [error, setError] = useState(false);
  const [shareApiAvailable, setShareApiAvailable] = useState(null);
  const [showTooltips, setShowTooltip] = useState(false);

  const link = offerData?.referral_link;
  const share = () => {
    console.log("Share Icon clicked");
    if (navigator.share) {
      try {
        navigator.share({
          title: "InKind",
          text: "Check out this amazing offer",
          url: offerData?.referral_link || "",
        });
      } catch (err) {
        console.log("Error sharing", err);
      }
    } else {
      console.log("Web Share API not supported");
    }
  };
  const copyLink = async () => {
    try {
      if (link) {
        // Copy from textRef if provided
        await navigator.clipboard.writeText(link);
      } else {
        // Otherwise, copy the entire document body
        await navigator.clipboard.writeText(document.body.textContent);
      } // Optional feedback to the user
      await setShowTooltip(true);
      await setTimeout(() => {
        setShowTooltip(false);
      }, 2500);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  useEffect(() => {
    async function fetchOfferData() {
      const headers = {
        Authorization: "Bearer ".concat(user.user.token),
        "content-type": "application/json",
      };
      await fetch("/api/v5/ambassadors", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          brand_slug: brandSlug,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            setError(true);
            return response.json();
          }
        })
        .then((response) => {
          setOfferData(response);
        })
        .catch((err) => {
          console.log("err", err);
          setError(true);
        });
    }
    fetchOfferData();
  }, []);
  const seeRestaurants = () => {
    console.log("See Restaurants clicked");
  };
  useEffect(() => {
    if (navigator.share) {
      setShareApiAvailable(true);
    } else {
      setShareApiAvailable(false);
    }
  }, []);

  return (
    <>
      {error ? (
        <Error brandName={""} disabled={true} />
      ) : (
        <div className="start-sharing-generated-link desktop">
          <div className="container">
            {/*<div className="see-restaurant-button">*/}
            {/*  <button*/}
            {/*    type="button"*/}
            {/*    onClick={seeRestaurants}*/}
            {/*    className="see-restaurants"*/}
            {/*  >*/}
            {/*    See Restaurants*/}
            {/*    <span className="icon-right">*/}
            {/*      <FontAwesomeIcon icon={faArrowRight} />*/}
            {/*    </span>*/}
            {/*  </button>*/}
            {/*</div>*/}
            <Header initialValues={initialValues} replaceLogo={false} />
            <main>
              <h1>Start sharing your custom link!</h1>
              <p>
                Let’s get going! Share the link below on your <br /> socials to
                get rewards
              </p>

              <CarouselSectionDesktop />

              <div className="referral-section">
                <p>
                  Share your referral link for&nbsp;
                  <span
                    css={css`
                      font-weight: 600;
                    `}
                  >
                    {`$${offerData?.offer?.amount} off $${offerData?.offer?.minimum_spend_amount}+`}
                  </span>
                </p>
                <div
                  className="referral-link desktop"
                  css={css`
                    border: 2px solid #000;
                  `}
                >
                  <span
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    {offerData?.referral_link || ""}
                  </span>
                  <div className="icons">
                    <span
                      onClick={copyLink}
                      className="copy-icon"
                      css={css`
                        width: 18px;
                        margin-right: 10px;
                        cursor: pointer;
                        position: relative;
                      `}
                    >
                      <img src={CopyIcon} alt={`${CopyIcon}-icon`} />
                      {showTooltips && <Tooltip text={"Copied"} />}
                    </span>
                    {shareApiAvailable && (
                      <span
                        onClick={share}
                        className="share-icon"
                        css={css`
                          width: 18px;
                          cursor: pointer;
                        `}
                      >
                        <img src={ShareIcon} alt={`${ShareIcon}-icon`} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <LearnMore
                offerData={offerData}
                initialValues={initialValues}
                desktop={true}
              />
              <Faqs desktop={true} />
              <GetAccessSection />
              <FooterDesktop />
            </main>
          </div>
        </div>
      )}
    </>
  );
}
