const currency = (val) => {
  return "$" + Number(val).toFixed(2);
};

const getCardDetails = (cardId, project, card = false) => {
  const matchingCard =
    project?.credit_cards.find((cc) => cc.id === cardId) || false;
  if (!matchingCard && project && card && card.id === cardId) {
    project.credit_cards.push(card);
    return getCardDetails(cardId, project);
  }
  return matchingCard;
};
function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

function today() {
  let date = new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  let datestr = date.toISOString().substring(0, 10);
  return datestr;
}

function isFutureDate(value, alwaysValidate) {
  if (alwaysValidate) {
    return true;
  }
  let d_now = new Date();
  d_now.setHours(0, 0, 0, 0);
  const dater = value.split("-");
  let d_inp = new Date(value);

  return d_now.getTime() <= convertDateToUTC(d_inp).getTime();
}

const getCookie = (key) =>
  document.cookie.split("; ").reduce((total, currentCookie) => {
    const item = currentCookie.split("=");
    const storedKey = item[0];
    const storedValue = item[1];

    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, "");
function deleteCookie() {
  const key = "userToken";
  const value = "";
  const numberOfDays = -365;
  const now = new Date();

  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + (numberOfDays || 0) * 60 * 60 * 24 * 1000);

  const hostParts = document.location.hostname.split(".");
  const isLocal = hostParts[hostParts.length - 1] === "localhost";
  const cookieDomain =
    "." +
    (isLocal
      ? hostParts[hostParts.length - 1]
      : (hostParts.length > 2 ? hostParts.slice(1) : hostParts).join("."));

  document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/${
    isLocal ? "" : `; secure; domain=${cookieDomain}; SameSite=Strict`
  }`;
}

const os = () => {
  const userAgent = String(
    navigator.userAgent || navigator.vendor || window.opera,
  );
  if (/android\s[0-9]+/i.test(userAgent)) return "android";
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "ios";
  return "desktop";
};

const smartJoin = (arr, conjunction) => {
  if (arr.length === 1) {
    return arr[0];
  }
  const listStart = arr.slice(0, -1).join(", ");
  const listEnd = arr.slice(-1);
  const conjunctionUse = `${arr.length === 2 ? " " : ", "}${conjunction} `;
  return [listStart, listEnd].join(conjunctionUse);
};

const createEmailRegex = (endings) => {
  // Escape special characters and create regex pattern
  let pattern =
    !endings || endings.length === 0
      ? "@[A-Za-z0-9\\.\\-]+(?:\\.[a-z]{2,})"
      : endings
          .map((ending) => {
            // Escape special regex characters
            let escapedEnding = ending.replace(/[*+?^${}()\.|[\]\\]/g, "\\$&");
            // Check if the ending starts with '@', if not, prepend it
            if (!escapedEnding.startsWith("@")) {
              escapedEnding = "@[A-Za-z0-9\\.\\-]+" + escapedEnding;
            }

            return escapedEnding;
          })
          .join("|");

  // Return the complete regex
  return "^[A-Za-z0-9.!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]+(" + pattern + ")$";
};

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  if (!phoneNumber) return null;
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength === 0) {
    return "";
  }
  if (phoneNumberLength === 1) {
    return phoneNumber === "1" ? "+" + phoneNumber : "+1 " + phoneNumber;
  }
  if (phoneNumberLength < 5) {
    return `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(1)}`;
  }
  if (phoneNumberLength < 8) {
    return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
      1,
      4,
    )}) ${phoneNumber.slice(4, phoneNumberLength)}`;
  }
  if (phoneNumberLength > 7) {
    return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
      1,
      4,
    )}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
  }
  return "";
};

const ikState = (slug, fallbackVal, fallbackData) => {
  function flagEnabled() {
    return fallbackVal;
  }
  function flagData() {
    return fallbackData;
  }
  if (slug && fallbackVal === true) {
    return flagEnabled();
  }
  if (slug && fallbackData === true) {
    return flagData();
  }
};

export {
  currency,
  getCardDetails,
  isFutureDate,
  getCookie,
  deleteCookie,
  os,
  createEmailRegex,
  formatPhoneNumber,
  ikState,
  smartJoin,
};
