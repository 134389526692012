import React from "react";
import { PartnerPortalTooltipProps } from "../helpers/types";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  alt: "Tooltip";
  type: "button";
}

function Button({ alt, type, ...props }: ButtonProps) {
  return <button type="button" {...props} />;
}

const PartnerPortalTooltip = ({
  name,
  label,
  tooltip,
  useOptions,
}: PartnerPortalTooltipProps) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <>
      {/* <button
        className="pp-input__tooltip-icon"
        alt="Tooltip"
        type="button"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      /> */}
      <Button
        className="pp-input__tooltip-icon"
        alt="Tooltip"
        type="button"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />
      {showTooltip ? (
        <div className="pp-input__tooltip-container">
          <div className="pp-input__tooltip">{tooltip}</div>
        </div>
      ) : null}
    </>
  );
};
export default PartnerPortalTooltip;
