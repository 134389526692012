import { InKindNetworkError } from "../../helpers/InKindNetworkError";
import fetch from "isomorphic-fetch";

export const handlePost = async (path, body, onFailure) => {
  const config = {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  };

  try {
    const response = await fetch(path, config);
    if (!response.ok) {
      const errorMessage = await response.text(); // Extract error message from response body
      const parsedError = JSON.parse(errorMessage);
      if (parsedError.error) {
        throw parsedError.error;
      } else if (parsedError.errors) {
        throw parsedError.errors[0];
      }
    }
    const resp = await response.json();
    if (onFailure) {
      onFailure("");
    }
    return resp;
  } catch (error) {
    let err;
    if (JSON.stringify(error) === "{}") {
      err = { message: "An unknown error occurred." };
    } else {
      err = InKindNetworkError(error);
    }
    if (onFailure) {
      onFailure(`• ${err?.message}`);
    }
    return false;
  }
};

export const handleGet = async (path, headers, onFailure) => {
  const config = {
    headers: headers,
  };

  try {
    const response = await fetch(path, config);
    if (!response.ok) {
      const errorMessage = await response.text(); // Extract error message from response body
      if (response?.status === 401) {
        throw {
          message:
            "Your login credentials are invalid or expired, please log in again.",
        };
      } else {
        throw JSON.parse(errorMessage).error;
      }
    }
    const resp = await response.json();
    if (onFailure) {
      onFailure("");
    }
    return resp;
  } catch (error) {
    let err;
    if (JSON.stringify(error) === "{}") {
      err = { message: "An unknown error occurred." };
    } else {
      err = InKindNetworkError(error);
    }
    if (onFailure) {
      onFailure(`• ${err?.message}`);
    }
    return false;
  }
};
