import React from "react";
import phoneHandIcon from "../../../assets/images/icons/phone-hand.svg";
import { handlePost } from "./utilities";

const VerifyYourEmail = ({
  handleResult,
  emailToken,
  isPartnerPortal,
  inviteToken,
}) => {
  const checkVerified = async () => {
    const path = "/api/v5/verifications/email/verify";
    const bodyJson = JSON.stringify({
      email_token: emailToken,
      invite_token: isPartnerPortal ? inviteToken : "",
    });
    const result = await handlePost(path, bodyJson);
    if (result) {
      handleResult(result);
    }
  };

  React.useEffect(() => {
    const checkInterval = setInterval(() => {
      checkVerified();
    }, 15000);

    //clear interval on unmount
    return () => {
      clearInterval(checkInterval);
    };
  }, []);

  return (
    <img src={phoneHandIcon} alt="Verify phone" className="login-ca__image" />
  );
};

export default VerifyYourEmail;
